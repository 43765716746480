.App {
  text-align: center;
  height:100vh;
}

h1, h2, h4{
  font-family: 'Bebas Neue', cursive;
}
h1,h4{
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}

.App-header {
  background-color: rgb(161, 17, 17); 
  padding: 0% 2%;
  margin-bottom: 4%;
  min-height: 10vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: calc(10px + 2vmin);
  color: white;
}

.form-container{
  width: 10%;
  padding: 2% 10%;
  margin: auto;
  background-color: gray;
  box-shadow: 2px 2px 5px black;
}
.form-group-input{
  width: 50%;
  display: flex;
  flex-direction: column;
}

.homepage-buttons{
  width: 20%;
  padding: 2% 10%;
  margin-top: 50%;
  margin: auto;
  background-color: gray;
  box-shadow: 2px 2px 5px black;
}

.errors{
  color: rgb(139, 8, 8);
}
.dashboard{
  display:flex;
  flex-direction: row;
}
.profile-info{
  width:25%;
  background-color: rgb(161, 17, 17);
}
.profile-info img{
border-radius: 50%;
}
.create-class{
  width:25%;
  background-color: grey;
}
.previous-class{
  width:25%;
  background-color: black;
  color:white;
}
.future-class{
  width:25%;
  background-color: black;
  color: white;
}
